import React from 'react';
//import { NavLink } from 'react-router-dom';

const services = [
    {
        count: 1,
        title: `Diseño Arquitectónico 3D`,
        icon: require('../../images/icon/compass.png'),
        image: require('../../images/SERVICIOS/servicio_1.jpg'),
        description: 'Generamos una propuesta virtual personalizada según tus requisitos y especificaciones de construcción, detallados en nuestro formulario en línea',
    },
    {
        count: 2,
        title: 'Estimación de Obras',
        icon: require('../../images/icon/renovation.png'),
        image: require('../../images/SERVICIOS/servicio_2.jpg'),
        description: 'Calculamos con precisión los costos de cada actividad basándonos en el diseño, generando memorias de cálculo para ajustar tu presupuesto.',
    },
    {
        count: 3,
        title: 'Dirección de Proyectos',
        icon: require('../../images/icon/crane-1.png'),
        image: require('../../images/SERVICIOS/servicio_3.jpg'),
        description: 'Construcción conforme al diseño arquitectónico y la estimación de obra, garantizando integridad estructural, seguridad y economia    .',
    }
      
]

class Services extends React.Component {
    render() {
        return (
            <>
                <div className="section-full bg-white slider-half-part">
                    <div className="container">
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="row">
                                {services.map((item, index) => (
                                    <div className="col-md-4 col-sm-4 m-b30" key={index}>
                                        <div className="half-blocks">
                                            <div className="mt-icon-box-wraper center m-b30">
                                                <div className="half-block-content icon-count-2 p-a30 p-tb50">
                                                    <span className="icon-count-number">{item.count}</span>
                                                    <div className="mt-icon-box-lg radius bg-gray m-b20 scale-in-center">
                                                        <span className="icon-cell"><img src={item.icon} alt=""/></span>
                                                    </div>
                                                    <div className="icon-content">
                                                        <h4 className="mt-tilte m-b25">{item.title}</h4>
                                                        <p>{item.description}</p>
                                                        {/*<NavLink to={"/services"} className="site-button-link" data-hover="Leer Mas">Leer Mas <i className="fa fa-angle-right arrow-animation" /></NavLink>*/}
                                                    </div>
                                                </div>
                                                <div className="bg-cover  half-blocks-bg" style={{ backgroundImage: 'url(' + item.image + ')' }} />
                                            </div>
                                        </div>
                                    </div>
                                        
                                    ))}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Services;