import React from 'react';
import { NavLink } from 'react-router-dom';
import Switcher from '../Elements/Switcher';

var bgimage = require('../../images/background/bg-site.png');

class Footer2 extends React.Component {
    constructor(props) {
        super(props);
        this.currentstate = {logo: require('../../images/Logo/LOGOCC.png')};
    }
    
    updateFooterLogo = (updatedlogo) => {this.currentstate.logo = updatedlogo;}
    render() {
        return (
            <>
                <footer className="site-footer footer-large  footer-light	footer-wide">
                    <div className="container call-to-action-wrap bg-no-repeat bg-center" style={{ backgroundImage: 'url('+bgimage+')' }}>
                        <div className="p-a30  bg-primary">
                            <div className="newsletter-bx">
                            </div>
                        </div>
                    </div>  
                    {/* FOOTER BLOCKES START */}
                    <div className="footer-top overlay-wraper">
                        <div className="overlay-main" />
                        <div className="container">
                            <div className="row">
                                {/* ABOUT COMPANY */}
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="widget widget_about">
                                        {/*<h4 class="widget-title">About Company</h4>*/}
                                        <div className="logo-footer clearfix p-b15">
                                        <NavLink to={"./"}><img src={this.currentstate.logo} alt="" /></NavLink>
                                        </div>
                                        <p className="max-w400"> Diseñamos soluciones arquitectónicas, te ofrecemos proyectos personalizados que sean tanto seguros como económicamente viables. Tu lo piensas, nosotros te lo construimos.</p>
                                        <ul className="social-icons  mt-social-links">
                                                <li><a href="https://www.facebook.com/consuconstruct.hn/" rel="noreferrer" target='_blank' className="fa fa-facebook"> </a></li>
                                                <li><a href="https://www.instagram.com/consuconstruct/" rel="noreferrer" target='_blank' className="fa fa-instagram"> </a></li>
                                                <li><a href="https://wa.me/50496628408" rel="noreferrer" target='_blank' className="fa fa-whatsapp"> </a></li>
                                                <li><a href="https://www.linkedin.com/company/64778879/admin/feed/posts/" rel="noreferrer" target='_blank' className="fa fa-linkedin"> </a></li>
                                        </ul>
                                    </div>
                                </div>
                                {/* RESENT POST */}
                                <div className="col-lg-4 col-md-6 col-sm-6">
                                    <div className="widget widget_address_outer">
                                        <h4 className="widget-title">Contáctenos</h4>
                                        <ul className="widget_address">
                                            <li>consuconstruct@gmail.com</li>
                                            <li>(+504) 9662-8408</li>
                                        </ul>
                                    </div>
                                </div>
                                {/* USEFUL LINKS */}
                                <div className="col-lg-4 col-md-6 col-sm-6 footer-col-3">
                                    <div className="widget widget_services inline-links">
                                        <h4 className="widget-title">Enlaces Rápidos</h4>
                                        <ul>
                                            {/*<li><NavLink to={"/about"}>Conócenos</NavLink></li>*/}
                                            {/*<li><NavLink to={"/ProyectGrid"}>Proyectos</NavLink></li>*/}
                                            <li><NavLink to={"/solicitud"}>Solicita tu Cotización</NavLink></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* FOOTER COPYRIGHT */}
                    <div className="footer-bottom overlay-wraper">
                        <div className="overlay-main" />
                        <div className="container">
                            <div className="row">
                                <div className="mt-footer-bot-center">
                                    <span className="copyrights-text">© 2024 Consuconstruct</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <Switcher updateFooterLogo={this.updateFooterLogo.bind(this)}/>

            </>
        );
    };
};

export default Footer2;