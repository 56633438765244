import React, { useState } from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Banner from '../Elements/Banner';
import axios from 'axios';

const bnrimg = require('../../images/CONTACTUS/background.jpg');

const ContactUs = () => {
    const [isOtherSpacesChecked, setIsOtherSpacesChecked] = useState(false);

    const handleCheckboxChange = (e) => {
        setIsOtherSpacesChecked(e.target.checked);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = {
            ownerName: e.target.ownerName.value,
            location: e.target.location.value,
            email: e.target.email.value,
            telefono: e.target.telefono.value,
            minBudget: e.target.minBudget.value,
            maxBudget: e.target.maxBudget.value,
            foundationType: Array.from(e.target.foundationType).filter(checkbox => checkbox.checked).map(checkbox => checkbox.value),
            finishes: Array.from(e.target.finishes).filter(checkbox => checkbox.checked).map(checkbox => checkbox.value),
            houseSize: e.target.houseSize.value,
            inhabitants: e.target.inhabitants.value,
            bedrooms: e.target.bedrooms.value,
            bathrooms: e.target.bathrooms.value,
            additionalSpaces: Array.from(e.target.additionalSpaces).filter(checkbox => checkbox.checked).map(checkbox => checkbox.value),
            otherSpaces: isOtherSpacesChecked ? e.target.otherSpaces.value : '',
        };
        
        axios.post('/api/request-quote', formData)
            .then(response => {
                alert(response.data);
                e.target.reset();
                setIsOtherSpacesChecked(false);
            })
            .catch(error => {
                console.error('Error al enviar el formulario:', error);
            });
    };

    return (
        <>
            <Header />
            <div className="page-content">
                <Banner title="Solicita tu estimación de acuerdo a tus necesidades y el tamaño de la obra." pagename="Haz tu Cotización" bgimage={bnrimg} />

                {/* SECTION CONTENT START */}
                <div className="section-full p-tb80 inner-page-padding">
                    {/* LOCATION BLOCK */}
                    <div className="container">
                        {/* GOOGLE MAP & CONTACT FORM */}
                        <div className="section-content">
                            {/* CONTACT FORM */}
                            <div className="row">
                                    <div className="col-md-8 col-sm-6">
                                    <form className="contact-form cons-contact-form" onSubmit={handleSubmit}>
                                        <div className="contact-one m-b30">
                                            <div className="section-head">
                                                <div className="mt-separator-outer separator-left">
                                                    <div className="mt-separator text-justify">
                                                        <h2 className="text-uppercase sep-line-one"><span className="font-weight-300 text-primary">Solicita tu</span> Estimación</h2>
                                                        <h5 style={{ fontWeight: 'normal' }}>
                                                            Solicite una cotización para su proyecto de construcción según sus requisitos específicos. Utilizamos un formulario para recopilar información sobre los espacios que necesita, y a partir de ello, creamos un modelo 3D, proporcionamos una estimación aproximada y presentamos una propuesta detallada en formato PDF que incluye todos los requisitos del proyecto.
                                                        </h5>
                                                        <h5 style={{ fontWeight: 'normal' }}>
                                                            Los costos varían según la cimentación, instalaciones eléctricas y sanitarias, obra gris, paredes, techo de estructura de madera o aluzinc, entrepisos de concreto desde losa sólida hasta losas aligeradas, estructuras o pórticos de concreto o acero, área de lavandería, paredes, puertas y ventanas.
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <input name="ownerName" type="text" required className="form-control" placeholder="Nombre del propietario" />
                                            </div>
                                            <div className="form-group">
                                                <input name="location" type="text" className="form-control" required placeholder="Lugar de Ubicación" />
                                            </div>
                                            <div className="form-group">
                                                <input name="email" type="email" className="form-control" required placeholder="Correo Electrónico" />
                                            </div>
                                            <div className="form-group">
                                                <input name="telefono" type="phone" className="form-control" required placeholder="Número Telefónico" />
                                            </div>
                                            <div className="form-group">
                                                <input name="minBudget" type="text" className="form-control" required placeholder="Presupuesto mínimo de construcción" />
                                            </div>
                                            <div className="form-group">
                                                <input name="maxBudget" type="text" className="form-control" required placeholder="Presupuesto máximo de construcción" />
                                            </div>
                                            <div className="form-group">
                                                <label>Análisis de tipo de Cimentación: Definida por Ing. D. Chinchilla</label>
                                                <div className="checkbox">
                                                    <label><input type="checkbox" name="foundationType" value="Cimentación superficial usando zapata corrida y muro de carga armado." /> Cimentación superficial usando zapata corrida y muro de carga armado.</label>
                                                </div>
                                                <div className="checkbox">
                                                    <label><input type="checkbox" name="foundationType" value="Cimentación con uso de zapata aislada: uso de columnas para estructura en pórtico." /> Cimentación con uso de zapata aislada: uso de columnas para estructura en pórtico.</label>
                                                </div>
                                                <div className="checkbox">
                                                    <label><input type="checkbox" name="foundationType" value="Muro de carga de bloque de 8”, armado, fundido en todos sus agujeros." /> Muro de carga de bloque de 8”, armado, fundido en todos sus agujeros.</label>
                                                </div>
                                                <div className="checkbox">
                                                    <label><input type="checkbox" name="foundationType" value="Muro de piedra colocada." /> Muro de piedra colocada.</label>
                                                </div>
                                                <div className="checkbox">
                                                    <label><input type="checkbox" name="foundationType" value="Losas en terraza." /> Losas en terraza.</label>
                                                </div>
                                                <div className="checkbox">
                                                    <label><input type="checkbox" name="foundationType" value="A definir en sitio por ingeniero supervisor." /> A definir en sitio por ingeniero supervisor.</label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>¿Cuáles son los acabados esperados?</label>
                                                <div className="checkbox">
                                                    <label><input type="checkbox" name="finishes" value="Acabado Básico" /> Acabado Básico: cerámica de piso, mueble de concreto en lavatrastos, baños completos.<br></br>Lps.12,500.00/m<sup>2</sup></label>
                                                </div>
                                                <div className="checkbox">
                                                    <label><input type="checkbox" name="finishes" value="Acabado Medio" /> Acabado Medio: Cielo falso tabla yeso, iluminación LED, lavatrastos 2 fosas, mejor cerámica, reserva de agua.<br></br>Lps.13,800.00/m</label>
                                                </div>
                                                <div className="checkbox">
                                                    <label><input type="checkbox" name="finishes" value="Acabados Full" /> Acabado Full: LED regulable smart, porcelanato en pisos, duchas, grifos importados USA, instalaciones RJ45, cielo falso PVC, cisterna, biodigestor.<br></br>Lps.15,500.00/m<sup>2</sup></label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <input name="houseSize" type="text" className="form-control" required placeholder="¿Cuál es el tamaño esperado de la vivienda? (m²)" />
                                            </div>
                                            <div className="form-group">
                                                <input name="inhabitants" type="text" className="form-control" required placeholder="¿Cuántas personas habitarán la vivienda?" />
                                            </div>
                                            <div className="form-group">
                                                <input name="bedrooms" type="text" className="form-control" required placeholder="¿Cuántas habitaciones es lo esperado?" />
                                            </div>
                                            <div className="form-group">
                                                <input name="bathrooms" type="text" className="form-control" required placeholder="¿Cuántos baños?" />
                                            </div>
                                            <div className="form-group">
                                                <label>¿Qué espacios serán considerados además de las habitaciones?</label>
                                                <div className="checkbox">
                                                    <label><input type="checkbox" name="additionalSpaces" value="Sala" /> Sala</label>
                                                </div>
                                                <div className="checkbox">
                                                    <label><input type="checkbox" name="additionalSpaces" value="Comedor" /> Comedor</label>
                                                </div>
                                                <div className="checkbox">
                                                    <label><input type="checkbox" name="additionalSpaces" value="Cocina" /> Cocina</label>
                                                </div>
                                                <div className="checkbox">
                                                    <label><input type="checkbox" name="additionalSpaces" value="Area de Lavanderia" /> Área de Lavandería</label>
                                                </div>
                                                <div className="checkbox">
                                                    <label><input type="checkbox" name="additionalSpaces" value="Area de Porch" /> Área de Porch</label>
                                                </div>
                                                <div className="checkbox">
                                                    <label><input type="checkbox" name="additionalSpaces" value="Area de Estudio" /> Área de Estudio</label>
                                                </div>
                                                <div className="checkbox">
                                                    <label><input type="checkbox" name="additionalSpaces" value="Area de Alacena o Bodega" /> Área de Alacena o Bodega</label>
                                                </div>
                                                <div className="checkbox">
                                                    <label><input type="checkbox" name="additionalSpaces" value="Closets de Madera /Melamina" /> Closets de Madera / Melamina</label>
                                                </div>
                                                <div className="checkbox">
                                                    <label><input type="checkbox" name="additionalSpaces" value="Walk in Closet" /> Walk in Closet</label>
                                                </div>
                                                <div className="checkbox">
                                                    <label><input type="checkbox" name="additionalSpaces" value="Area de Garaje" /> Área de Garaje</label>
                                                </div>
                                                <div className="checkbox">
                                                    <label><input type="checkbox" name="additionalSpaces" value="Muro perimetral" /> Muro perimetral</label>
                                                </div>
                                                <div className="checkbox">
                                                    <label><input type="checkbox" name="additionalSpaces" value="Obras exteriores" /> Obras exteriores</label>
                                                </div>
                                                <div className="form-group">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input
                                                                type="checkbox"
                                                                checked={isOtherSpacesChecked}
                                                                onChange={handleCheckboxChange}
                                                            />
                                                            Otro
                                                        </label>
                                                    </div>
                                                    <input
                                                        name="otherSpaces"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Especifique"
                                                        disabled={!isOtherSpacesChecked}
                                                    />
                                                </div>
                                            </div>
                                            <div className="text-right">
                                                <button type="submit" value="Submit" className="site-button btn-effect">Enviar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="contact-info m-b30">
                                        <div className="bg-dark p-a20 text-white">
                                            <div className="mt-icon-box-wraper left p-b40">
                                                <div className="icon-xs"><i className="fa fa-phone" /></div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">Gerente de Proyectos</h5>
                                                    <p>Ing. David Chinchilla</p>
                                                </div>
                                            </div>
                                            <div className="mt-icon-box-wraper left p-b40">
                                                <div className="icon-xs"><i className="fa fa-phone" /></div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">Número de Teléfono</h5>
                                                    <p>(+504) 9662-8408</p>
                                                </div>
                                            </div>
                                            <div className="mt-icon-box-wraper left p-b40">
                                                <div className="icon-xs"><i className="fa fa-envelope" /></div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">Dirección de Correo</h5>
                                                    <p>consuconstruct@gmail.com</p>
                                                </div>
                                            </div>
                                            <div className="bg-dark p-a20 text-white">
                                            <h3>Requisitos para asalariados</h3>
                                            <ul>
                                                <li>Copia de Identidad (legible) al 150%</li>
                                                <li>Copia de RTN (legible) al 150%</li>
                                                <li>Constancia de trabajo con deducciones</li>
                                                <li>Constancia de No poseer bienes inmuebles/ubicación catastral</li>
                                                <li>Croquis de vivienda actual (ubicación con coordenadas)</li>
                                                <li>Recibo de Servicios Públicos (actualizado)</li>
                                                <li>Constancia bancaria con saldos promedio 6 meses</li>
                                                <li>Estados de cuenta últimos 12 meses de la cuenta de planilla</li>
                                            </ul>
                                            <h3>Documentos de la vivienda</h3>
                                            <ul>
                                                <li>Copia de Escritura pública del terreno</li>
                                                <li>Copia de DNI y RTN del dueño y de la empresa, si aplica</li>
                                                <li>Pago de bienes inmuebles al día</li>
                                                <li>Croquis del Proyecto (ubicación con coordenadas)</li>
                                                <li>Promesa de Compra Venta (firma y huella)</li>
                                                <li>Planos y presupuesto (Firmados y sellados por CONSUCONSTRUCT)</li>
                                                <li>Avaluo de la propiedad (Realizado por entidad bancaria o CONSUCONSTRUCT)</li>
                                            </ul>
                                        </div>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
                {/* SECTION CONTENT END */}
            </div>

            <Footer />
        </>
    );
};

export default ContactUs;