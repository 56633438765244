import React from 'react';
import Navigation from './Navigation';
import { NavLink } from 'react-router-dom';

var bnr = require('../../images/background/bg-5.png');

class Header extends React.Component {

    constructor(props){
        super(props);
        this.state = {logo: require('../../images/Logo/LOGOCC.png')};
    }

    handleQuoteToggle = () => {
        this.setState({ isQuoteActive: !this.state.isQuoteActive });
    };

    componentDidMount() {

        const handleScroll = () => {
            const offset = window.scrollY;

            const stickyheader = document.querySelector('.sticky-header ');

            if (offset >= 100) {
                stickyheader.classList.add('is-fixed');
                stickyheader.classList.add('color-fill');

            } else {
                stickyheader.classList.remove('is-fixed');
                stickyheader.classList.remove('color-fill');
            }
        }

        window.addEventListener('scroll', handleScroll);

        window.updateTopMostParent = (logopath) => {
           this.setState({ logo: logopath }); 
        };
    }

    render() {

        const isQuoteActive = this.state.isQuoteActive;
        
        return (
            <>

                <header className="site-header header-style-2">
                    {/* Search Form */}
                    <div className="header-middle bg-white">
                        <div className="container">
                            <div className="logo-header">
                                <NavLink to={"./"}>
                                    <img src={this.state.logo} width={216} height={37} alt="Consuconstruct" />
                                </NavLink>
                            </div>
                            <div className="header-info">
                                <ul>
                                    <li>
                                        <div className="icon-sm">
                                            <span className="icon-cell  text-primary"><i className="fa fa-phone" /></span>
                                        </div>
                                        <div className="icon-content">
                                            <strong>(+504) 9662-8408</strong>
                                            <span>Lun - Dom hasta las 8 pm</span>
                                        </div>
                                    </li>
                                    <li className="btn-col-last">
                                        <NavLink className="site-button-secondry btn-effect" to={"/solicitud"}>SOLICITA TU ESTIMACIÓN</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* Search Form */}
                    <div className="sticky-header main-bar-wraper">
                        <div className="main-bar header-botton nav-bg-secondry">
                            <div className="container">
                                {/* NAV Toggle Button */}
                                <button data-target=".header-nav" data-toggle="collapse" type="button" className="navbar-toggle collapsed">
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar" />
                                    <span className="icon-bar" />
                                    <span className="icon-bar" />
                                </button>
                                {/* ETRA Nav */}
                                <div className="extra-nav">
                                    <div className="extra-cell">
                                        <NavLink to={"#"} className="contact-slide-show" onClick={this.handleQuoteToggle}><i className="fa fa-angle-left arrow-animation" /></NavLink>
                                    </div>
                                </div>
                                {/* ETRA Nav */}
                                {/* Contact Nav */}
                                <div className="contact-slide-hide " style={{ backgroundImage: 'url(' + bnr + ')', right: isQuoteActive ? '0px' : '-500px' }}>
                                    <div className="contact-nav">
                                        <NavLink to={"#"} className="contact_close" onClick={this.handleQuoteToggle}>×</NavLink>
                                        <div className="contact-nav-form p-a30">
                                            <div className="contact-info   m-b30">
                                                <div className="mt-icon-box-wraper center p-b30">
                                                    <div className="icon-xs m-b20 scale-in-center"><i className="fa fa-phone" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Numero de Teléfono</h5>
                                                        <p>+504 9662 8408</p>
                                                    </div>
                                                </div>
                                                <div className="mt-icon-box-wraper center p-b30">
                                                    <div className="icon-xs m-b20 scale-in-center"><i className="fa fa-envelope" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Dirección de Correo</h5>
                                                        <p>consuconstruct@gmail.com</p>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div className="full-social-bg">
                                                <ul>
                                                    <li><a href="https://www.facebook.com/consuconstruct.hn/" rel="noreferrer" target='_blank' className="fa fa-facebook"> </a></li>
                                                    <li><a href="https://www.instagram.com/consuconstruct/" rel="noreferrer" target='_blank' className="fa fa-instagram"> </a></li>
                                                    <li><a href="https://wa.me/50496628408" rel="noreferrer" target='_blank' className="fa fa-whatsapp"> </a></li>
                                                    <li><a href="https://www.linkedin.com/company/64778879/admin/feed/posts/" rel="noreferrer" target='_blank' className="fa fa-linkedin"> </a></li>
                                                </ul>
                                            </div>
                                            <div className="text-center">
                                                <h4 className="font-weight-600">©  2024 CONSUCONSTRUCT</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* MAIN Nav */}
                                <Navigation />
                            </div>
                        </div>
                    </div>
                </header>
            </>
        );
    };
};

export default Header;

