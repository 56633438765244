import React, { useState } from 'react';
import axios from 'axios';

const OurMission = () => {
    
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/send-email', {
                name: formData.name,
                email: formData.email,
                message: `Correo de Contacto: ${formData.email}\nTelefono: ${formData.phone}\nMensaje: ${formData.message}`
            });
            alert(response.data);
            // Limpiar el formulario después de enviar
            setFormData({
                name: '',
                email: '',
                phone: '',
                message: ''
            });
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Error sending email');
        }
    };

    return (
        <>
            <div className="section-full bg-dark " id="our-mission">
                <div className="section-content">
                    <div className="container get-in-touch-form">
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <div className="contact-home1-left contact-home1-left-v2 bg-dark p-a30 m-b30 p-t50">
                                    <h3 className="text-white m-t0">Contáctenos</h3>
                                    <form className="cons-contact-form2 form-transparent" onSubmit={handleSubmit}>
                                        <div className="input input-animate">
                                            <label htmlFor="name">Nombre</label>
                                            <input type="text" name="name" id="name" required onChange={handleChange} value={formData.name} />
                                            <span className="spin" />
                                        </div>
                                        <div className="input input-animate">
                                            <label htmlFor="email">Correo Electrónico</label>
                                            <input type="email" name="email" id="email" required onChange={handleChange} value={formData.email} />
                                            <span className="spin" />
                                        </div>
                                        <div className="input input-animate">
                                            <label htmlFor="Phone">Teléfono</label>
                                            <input type="text" name="phone" id="Phone" required onChange={handleChange} value={formData.phone} />
                                            <span className="spin" />
                                        </div>
                                        <div className="input input-animate">
                                            <label htmlFor="message">Escribe tu pregunta</label>
                                            <textarea name="message" id="message" required onChange={handleChange} value={formData.message} />
                                            <span className="spin" />
                                        </div>
                                        <div className="text-left p-t10">
                                            <button type="submit" className="site-button btn-effect ">
                                                Enviar
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="get-in-touch-form-section">
                                <div className="get-in-touch-form-section-img"><img src={require('../../images/OUR_MISSION/engineers.png')} alt="" style={{ width: '425px', height: 'auto' }}/></div>
                                <div className="get-in-touch-info arrow-animation">
                                    <div className="mt-icon-box-wraper  p-a20 left bg-white hover-shadow">
                                        <div className="icon-md m-b20 scale-in-center">
                                            <span className="icon-cell text-secondry"><img src={require('../../images/icon/icon-img-01.png')} alt="" /></span>
                                        </div>
                                        <div className="icon-content">
                                            <h4 className="mt-tilte m-b0"></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default OurMission;