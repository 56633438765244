import React from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Slider from '../Elements/Slider';
import Services from '../Elements/Services';
import OurValue from '../Elements/OurValue';
import OurMission from '../Elements/OurMission';
import OurServices from '../Elements/OurServices';
import Callus from '../Elements/Callus';
import OurProject from '../Elements/OurProject';
import Pricing from '../Elements/Pricing';
import ClientsLogo from '../Elements/ClientsLogo';

class Home extends React.Component {
    render() {
        return (
            <>
                <Header />
                    <div className="page-content">
                        <Slider />
                        <Services />
                        <OurValue/>
                        <OurMission/>
                        <OurServices/>
                        <Callus/>
                        <OurProject/>
                        {/*<Pricing/>
                        <ClientsLogo/>*/}
                   </div>
                <Footer />

            </>
        );
    };
};

export default Home;