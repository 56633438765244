import React, { useState } from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Banner from '../Elements/Banner';
import axios from 'axios';

const bnrimg = require('../../images/CONTACTUS/background.jpg');

const PrequalificationForm = () => {
    // Estado para manejar la disponibilidad de los inputs basada en los checkboxes
    const [hasBeenPrequalified, setHasBeenPrequalified] = useState(false);
    const [hasLongEmployment, setHasLongEmployment] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = {
            clientName: e.target.clientName.value,
            phoneNumber: e.target.phoneNumber.value,
            email: e.target.email.value,
            locationPreference: e.target.locationPreference.value,
            budgetForLot: e.target.budgetForLot.value,
            budgetForConstruction: e.target.budgetForConstruction.value,
            bankName: hasBeenPrequalified ? e.target.bankName.value : '',
            longEmployment: hasLongEmployment
        };

        axios.post('/api/submit-lot-construction', formData)
            .then(response => {
                alert('Formulario enviado exitosamente.');
                e.target.reset();
                setHasBeenPrequalified(false);
                setHasLongEmployment(false);
            })
            .catch(error => {
                console.error('Error al enviar el formulario:', error);
            });
    };

    return (
        <>
            <Header />
            <div className="page-content">
                <Banner title="Precalifica para tu Financiamiento en tu Entidad Financiera Preferida" pagename="Precalificación" bgimage={bnrimg} />

                <div className="section-full p-tb80 inner-page-padding">
                    <div className="container">
                        <div className="section-content">
                            <div className="row">
                                <div className="col-md-8 col-sm-6">
                                    <form className="contact-form cons-contact-form" onSubmit={handleSubmit}>
                                        <div className="contact-one m-b30">
                                            <div className="section-head">
                                                <div className="mt-separator-outer separator-left">
                                                    <div className="mt-separator text-justify">
                                                        <h2 className="text-uppercase sep-line-one"><span className="font-weight-300 text-primary">Fomrulario de</span> Precalificación</h2>
                                                        <h5 style={{ fontWeight: 'normal' }}>   
                                                            Como empresa de diseño y construcción de viviendas, tratamos de facilitarte el 
                                                            proceso de obtención de financiamiento por medio del análisis de tu información 
                                                            crediticia en el banco donde tengas deposito de planilla o cuenta de ahorro. 
                                                            Una vez revisado tu caso, te contactamos para solicitar la información adicional 
                                                            que solicitan para la preparación del desembolso.                                                     
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Campos del formulario */}
                                            <div className="form-group">
                                                <input name="clientName" type="text" required className="form-control" placeholder="Nombre del Cliente" />
                                            </div>
                                            <div className="form-group">
                                                <input name="phoneNumber" type="phone" className="form-control" required placeholder="Número de Teléfono" />
                                            </div>
                                            <div className="form-group">
                                                <input name="email" type="email" className="form-control" required placeholder="Correo Electrónico" />
                                            </div>
                                            <div className="form-group">
                                                <input name="locationPreference" type="text" className="form-control" required placeholder="Lugar de Preferencia" />
                                            </div>
                                            <div className="form-group">
                                                <input name="budgetForLot" type="text" className="form-control" required placeholder="Presupuesto para lote solamente" />
                                            </div>
                                            <div className="form-group">
                                                <input name="budgetForConstruction" type="text" className="form-control" required placeholder="Presupuesto para construcción" />
                                            </div>
                                            <div className="checkbox">
                                                <label>
                                                    <input type="checkbox" checked={hasBeenPrequalified} onChange={() => setHasBeenPrequalified(!hasBeenPrequalified)} />
                                                    ¿Ha sido precalificado anteriormente?
                                                </label>
                                                <input name="bankName" type="text" className="form-control" placeholder="Nombre del banco" disabled={!hasBeenPrequalified} />
                                            </div>
                                            <div className="checkbox">
                                                <label>
                                                    <input type="checkbox" checked={hasLongEmployment} onChange={() => setHasLongEmployment(!hasLongEmployment)} />
                                                    ¿Más de un año laborando en la misma empresa?
                                                </label>
                                            </div>
                                            <div className="text-right">
                                                <button type="submit" className="site-button btn-effect">Enviar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="contact-info m-b30">
                                        <div className="bg-dark p-a20 text-white">
                                            <div className="mt-icon-box-wraper left p-b40">
                                                <div className="icon-xs"><i className="fa fa-phone" /></div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">Gerente de Proyectos</h5>
                                                    <p>Ing. David Chinchilla</p>
                                                </div>
                                            </div>
                                            <div className="mt-icon-box-wraper left p-b40">
                                                <div className="icon-xs"><i className="fa fa-phone" /></div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">Número de Teléfono</h5>
                                                    <p>(+504) 9662-8408</p>
                                                </div>
                                            </div>
                                            <div className="mt-icon-box-wraper left p-b40">
                                                <div className="icon-xs"><i className="fa fa-envelope" /></div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">Dirección de Correo</h5>
                                                    <p>consuconstruct@gmail.com</p>
                                                </div>
                                            </div>
                                            <div className="bg-dark p-a20 text-white">
                                            <h3>Requisitos para asalariados</h3>
                                            <ul>
                                                <li>Copia de Identidad (legible) al 150%</li>
                                                <li>Copia de RTN (legible) al 150%</li>
                                                <li>Constancia de trabajo con deducciones</li>
                                                <li>Constancia de No poseer bienes inmuebles/ubicación catastral</li>
                                                <li>Croquis de vivienda actual (ubicación con coordenadas)</li>
                                                <li>Recibo de Servicios Públicos (actualizado)</li>
                                                <li>Constancia bancaria con saldos promedio 6 meses</li>
                                                <li>Estados de cuenta últimos 12 meses de la cuenta de planilla</li>
                                            </ul>
                                            <h3>Documentos de la vivienda</h3>
                                            <ul>
                                                <li>Copia de Escritura pública del terreno</li>
                                                <li>Copia de DNI y RTN del dueño y de la empresa, si aplica</li>
                                                <li>Pago de bienes inmuebles al día</li>
                                                <li>Croquis del Proyecto (ubicación con coordenadas)</li>
                                                <li>Promesa de Compra Venta (firma y huella)</li>
                                                <li>Planos y presupuesto (Firmados y sellados por CONSUCONSTRUCT)</li>
                                                <li>Avaluo de la propiedad (Realizado por entidad bancaria o CONSUCONSTRUCT)</li>
                                            </ul>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
            <Footer />
        </>
    );
};

export default PrequalificationForm;
