import React from 'react';
//import { NavLink } from 'react-router-dom';

const services = [
    {
        count: 1,
        title: 'Diseño Arquitectónico',
        icon: require('../../images/icon/architecture.png'),
        description: 'Generamos una propuesta virtual personalizada según tus requisitos y especificaciones de construcción, detallados en nuestro formulario en línea.',
    },
    {
        count: 2,
        title: 'Planos y Presupuesto',
        icon: require('../../images/icon/blueprint.png'),
        description: 'Calculamos con precisión los costos de cada actividad basándonos en el diseño, generando memorias de cálculo para ajustar tu presupuesto.',
    },
    {
        count: 3,
        title: 'Dirección de Proyectos',
        icon: require('../../images/icon/engineer.png'),
        description: 'Construcción conforme al diseño arquitectónico y la estimación de obra, garantizando integridad estructural, seguridad maximizando eficiencia laboral',
    },
    {
        count: 4,
        title: 'Estimaciones de Obra',
        icon: require('../../images/icon/calculations.png'),
        description: 'Mantenemos una comunicación constante con el cliente, proporcionando actualizaciones diarias sobre el progreso a través de bitácoras de gastos y fotografías.',
    },
    {
        count: 5,
        title: 'Consultorías de Obra    ',
        icon: require('../../images/icon/idea.png'),
        description: '¿Requieres asesoramiento en estructura, arquitectura o finanzas para tu proyecto? Realizamos una auditoría exhaustiva de tu obra.',
    },
    {
        count: 6,
        title: 'Jardineria y Landscaping',
        icon: require('../../images/icon/dump-truck.png'),
        description: 'Selección de especies, embellecimiento de espacios verdes, paisajes exteriores y mantenimiento con sistemas de riego.',
    },
    {
        count: 7,
        title: 'Levantamientos Topograficos',
        icon: require('../../images/icon/theodolite.png'),
        description: 'Levantamientos topográficos georeferencindor el terreno conforme a los rumbos definidos en escrituras, creando planos, curvas de nivel y solicitud de estudios de suelo.',
    },
    {
        count: 8,
        title: 'Renovaciones y Restauraciones',
        icon: require('../../images/icon/renovation.png'),
        description: 'Realizamos mediciones detalladas de la obra existente, identificando sus características estructurales y evaluando la factibilidad de adiciones, renovaciones y restauraciones.',
    }
]

var img1 = require('../../images/background/ptn-1.png');

class OurServices2 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-white mobile-page-padding" style={{ backgroundImage: 'url(' + img1 + ')' }}>
                    <div className="section-content">
                        <div className="container">
                            {/* TITLE START */}
                            <div className="section-head m-b50">
                                <div className="mt-separator-outer separator-left">
                                    <div className="mt-separator">
                                        <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Nuestros</span> Servicios</h2>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                            <div className="row services-v2 no-col-gap">
                            {services.map((item, index) => (
                                <div className="col-md-3 col-sm-6 col-xs-6 col-xs-100pc" key={index}>
                                    <div className="mt-icon-box-wraper bdr-l-1 bdr-solid bdr-gray" style={{ height: '400px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                        <div className="relative  p-a30">
                                            <div className="icon-md inline-icon m-b15 text-primary scale-in-center">
                                                <span className="icon-cell"><img src={item.icon} alt="" /></span>
                                            </div>
                                            <div className="icon-content">
                                                <h4 className="mt-tilte m-b25">{item.title}</h4>
                                                <p>{item.description}</p>
                                                {/*<NavLink to={"/services"} className="site-button-link" data-hover="Read More">Leer Más <i className="fa fa-angle-right arrow-animation" /></NavLink>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    ))}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default OurServices2;