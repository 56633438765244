import React from 'react';
import { NavLink } from 'react-router-dom';
import ReactPlayer from 'react-player';


var img1 = require('../../images/OUR_VALUES/imagen_1.png');

class OurValue2 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-white mobile-page-padding">
                    <div className="container">
                        <div className="section-content">
                            <div className="row">
                                <div className="col-md-7 col-sm-6">
                                    <div className="video-section-full-v2">
                                        <div className="video-section-full bg-no-repeat bg-cover overlay-wraper m-b30" style={{ backgroundImage: 'url(' + img1 + ')' }}>
                                            <div className="overlay-main bg-black opacity-04" />
                                                <div className="video-section-inner">
                                                    <div className="video-section-content">
                                                        <div className="video-section-left">
                                                            <button type="button" className="play-now" data-toggle="modal" data-target="#myModal">Open Modal
                                                                <i className="icon fa fa-play" />
                                                                <span className="ripple" />
                                                            </button>
                                                        </div>
                                                        <div className="video-section-right">
                                                            <NavLink to={"#"} className="font-weight-600 text-uppercase" onClick={this.openModal}></NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 col-sm-6">
                                    {/* TITLE START */}
                                    <div className="video-part-2">
                                        <h3><span className="font-weight-100">Supervisamos la construcción de principio a fin con</span> profesionalismo absoluto</h3>
                                        <p>Con una amplia trayectoria en el sector de la construcción, aplicamos sistemas y técnicas probadas que garantizan la plena satisfacción de nuestros clientes</p>
                                        {/*<ul className="list-angle-right anchor-line">
                                            <li><NavLink to={"/services"}>Diseño Arquitectónico 3D</NavLink></li>
                                            <li><NavLink to={"/services"}>Estimación de Obras</NavLink></li>
                                            <li><NavLink to={"/services"}>Dirección de Proyectos</NavLink></li>
                                        </ul>*/}
                                        <ul className="list-angle-right anchor-line">
                                            <li>Diseño Arquitectónico 3D</li>
                                            <li>Estimación de Obras</li>
                                            <li>Dirección de Proyectos</li>
                                        </ul>
                                    </div>
                                    {/* TITLE END */}
                                    <div className="author-info author-info-v2 p-t20">
                                        <div className="author-signature">
                                            <img src={require('../../images/OUR_VALUES/firma_1.PNG')} alt="Signature" width={200} />
                                        </div>
                                        <div className="author-name">
                                            <h4 className="m-t0">Ing. David Chinchilla</h4>
                                            <p>Gerente de Proyectos</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="myModal" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <ReactPlayer url='https://youtu.be/orePa_MhoDc' controls/>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default OurValue2;