import React from 'react';
import { NavLink } from 'react-router-dom';

var bnr1 = require('../../images/CONTACTUS/Image33.png');

class Callus2 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-tb60 overlay-wraper bg-center bg-parallax bg-cover" data-stellar-background-ratio="0.7" style={{backgroundImage:"url(" + bnr1 + ")"}}>
                    <div className="overlay-main bg-black opacity-07" />
                    <div className="container">
                        <div className="section-content">
                            <div className="call-us-section text-center text-white">
                                <h4 className="m-b15">Trabajemos juntos!</h4>
                                <h2 className="call-us-number m-b15 m-b0">(+504) 9662-8408</h2>
                                <NavLink to="/solicitud" className="site-button btn-effect">HAZ TU COTIZACIÓN</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Callus2;