import React, { useState } from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Banner from '../Elements/Banner';
import axios from 'axios';

const bnrimg = require('../../images/CONTACTUS/background.jpg');

const ServiceRequestForm = () => {
    const [selectedServices, setSelectedServices] = useState([]);

    const handleServiceChange = (service) => {
        if (selectedServices.includes(service)) {
            setSelectedServices(selectedServices.filter(s => s !== service));
        } else {
            setSelectedServices([...selectedServices, service]);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = {
            fullName: e.target.fullName.value,
            identityNumber: e.target.identityNumber.value,
            phoneNumber: e.target.phoneNumber.value,
            email: e.target.email.value,
            services: selectedServices,
            totalArea: e.target.totalArea.value,
            assumedConstructionArea: e.target.assumedConstructionArea.value,
            maxInvestment: e.target.maxInvestment.value,
            minInvestment: e.target.minInvestment.value
        };

        axios.post('/api/submit-service-request', formData)
            .then(response => {
                alert('Formulario enviado exitosamente.');
                e.target.reset();
                setSelectedServices([]);
            })
            .catch(error => {
                console.error('Error al enviar el formulario:', error);
            });
    };

    return (
        <>
            <Header />
            <div className="page-content">
                <Banner title="Nos Especializamos en la Realización de Proyectos Comerciales" pagename="Proyectos Comerciales" bgimage={bnrimg} />

                <div className="section-full p-tb80 inner-page-padding">
                    <div className="container">
                        <div className="section-content">
                            <div className="row">
                                <div className="col-md-8 col-sm-6">
                                    <form className="contact-form cons-contact-form" onSubmit={handleSubmit}>
                                        <div className="contact-one m-b30">
                                            <div className="section-head">
                                                <div className="mt-separator-outer separator-left">
                                                    <div className="mt-separator">
                                                        <h2 className="text-uppercase sep-line-one"><span className="font-weight-300 text-primary">Formulario para</span> Proyectos Comerciales</h2>
                                                        <h5 style={{ fontWeight: 'normal' }}>   
                                                            ¡Bienvenido al formulario de solicitud de construcción de proyectos comerciales de Consuconstruct!
                                                        </h5>
                                                        <h5 style={{ fontWeight: 'normal' }}>
                                                            En Consuconstruct, nos especializamos en la realización de proyectos comerciales de cualquier envergadura. 
                                                            Desde proyectos comunitarios como escuelas y lugares de culto, hasta desarrollos residenciales como 
                                                            apartamentos y complejos de oficinas, así como proyectos de infraestructura como bloqueras y estaciones de servicio.
                                                        </h5>
                                                        <h5 style={{ fontWeight: 'normal' }}>
                                                            Nos dedicamos a crear propuestas constructivas, si no tienes lote, 
                                                            puedes dejarnos tu información completa y nosotros los contactamos con las 
                                                            inmobiliarias seleccionadas para que puedan enviarte opciones especificas 
                                                            que puedan ser financiables                                                        
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Campos del formulario */}
                                            <div className="form-group">
                                                <input name="fullName" type="text" required className="form-control" placeholder="Nombre completo" />
                                            </div>
                                            <div className="form-group">
                                                <input name="identityNumber" type="text" className="form-control" required placeholder="Número de identidad" />
                                            </div>
                                            <div className="form-group">
                                                <input name="phoneNumber" type="phone" className="form-control" required placeholder="Número de teléfono" />
                                            </div>
                                            <div className="form-group">
                                                <input name="email" type="email" className="form-control" required placeholder="Correo electrónico" />
                                            </div>
                                            <div className="form-group">
                                                <label>¿Qué servicios de Consuconstruct desea contratar? (Seleccione todos los que correspondan)</label>
                                                {[
                                                    'Asesoramiento directo',
                                                    'Diseño de construcción bajo parámetros',
                                                    'Estimaciones de obra',
                                                    'Cálculo de cantidades de obra',
                                                    'Zonificación de lote',
                                                    'Desmembramientos',
                                                    'Renderizado 3D',
                                                    'Planos arquitectónicos',
                                                    'Planos mecánicos',
                                                    'Planos estructurales',
                                                    'Planos de ventilación de circuitos eléctricos',
                                                    'Planos de fontanería'
                                                ].map(service => (
                                                    <div key={service} className="checkbox">
                                                        <label>
                                                            <input type="checkbox" checked={selectedServices.includes(service)} onChange={() => handleServiceChange(service)} />
                                                            {service}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="form-group">
                                                <input name="totalArea" type="text" className="form-control" required placeholder="¿Cuál es el área total de la obra? (En metros cuadrados)" />
                                            </div>
                                            <div className="form-group">
                                                <input name="assumedConstructionArea" type="text" className="form-control" required placeholder="¿Cuál es el área que se asume a construir en la obra? (En metros cuadrados o porcentaje)" />
                                            </div>
                                            <div className="form-group">
                                                <input name="maxInvestment" type="text" className="form-control" required placeholder="¿Cuál es la inversión máxima prevista para el proyecto?" />
                                            </div>
                                            <div className="form-group">
                                                <input name="minInvestment" type="text" className="form-control" required placeholder="¿Cuál es la inversión mínima prevista para el proyecto?" />
                                            </div>
                                            <div className="text-right">
                                                <button type="submit" className="site-button btn-effect">Enviar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="contact-info m-b30">
                                        <div className="bg-dark p-a20 text-white">
                                            <div className="mt-icon-box-wraper left p-b40">
                                                <div className="icon-xs"><i className="fa fa-phone" /></div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">Gerente de Proyectos</h5>
                                                    <p>Ing. David Chinchilla</p>
                                                </div>
                                            </div>
                                            <div className="mt-icon-box-wraper left p-b40">
                                                <div className="icon-xs"><i className="fa fa-phone" /></div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">Número de Teléfono</h5>
                                                    <p>(+504) 9662-8408</p>
                                                </div>
                                            </div>
                                            <div className="mt-icon-box-wraper left p-b40">
                                                <div className="icon-xs"><i className="fa fa-envelope" /></div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">Dirección de Correo</h5>
                                                    <p>consuconstruct@gmail.com</p>
                                                </div>
                                            </div>
                                            <div className="bg-dark p-a20 text-white">
                                                <h3>Requisitos para asalariados</h3>
                                                <ul>
                                                    <li>Copia de Identidad (legible) al 150%</li>
                                                    <li>Copia de RTN (legible) al 150%</li>
                                                    <li>Constancia de trabajo con deducciones</li>
                                                    <li>Constancia de No poseer bienes inmuebles/ubicación catastral</li>
                                                    <li>Croquis de vivienda actual (ubicación con coordenadas)</li>
                                                    <li>Recibo de Servicios Públicos (actualizado)</li>
                                                    <li>Constancia bancaria con saldos promedio 6 meses</li>
                                                    <li>Estados de cuenta últimos 12 meses de la cuenta de planilla</li>
                                                </ul>
                                                <h3>Documentos de la vivienda</h3>
                                                <ul>
                                                    <li>Copia de Escritura pública del terreno</li>
                                                    <li>Copia de DNI y RTN del dueño y de la empresa, si aplica</li>
                                                    <li>Pago de bienes inmuebles al día</li>
                                                    <li>Croquis del Proyecto (ubicación con coordenadas)</li>
                                                    <li>Promesa de Compra Venta (firma y huella)</li>
                                                    <li>Planos y presupuesto (Firmados y sellados por CONSUCONSTRUCT)</li>
                                                    <li>Avaluo de la propiedad (Realizado por entidad bancaria o CONSUCONSTRUCT)</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ServiceRequestForm;