import React from 'react';
import { NavLink } from 'react-router-dom';

class Navigation extends React.Component {

    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/masonary.js');

    };

    /*
    Hacer que el color de los items en el Navbar cambien de acuerdo a la pagina que se esta viendo
    */

    render() {
        return (
            <>
                <div className={(this.props.bgcolor !== '') ? `header-nav navbar-collapse collapse ${this.props.bgcolor}` : 'header-nav navbar-collapse collapse'}>
                    <ul className="nav navbar-nav">
                        <li>
                            <NavLink to={"/"}>INICIO</NavLink>
                        </li>
                        <li>
                            <NavLink className="text-center" to={"/solicitud"}>SOLICITA UNA<br></br>PROPUESTA</NavLink>
                        </li>
                        <li>
                            <NavLink className="text-center" to={"/precalificacion"}>SOLICITA TU<br></br>FINANCIAMIENTO</NavLink>
                        </li>
                        <li>
                            <NavLink className="text-center" to={"/lotes"}>LOTES<br></br>DISPONIBLES</NavLink>
                        </li>
                        <li>
                            <NavLink className="text-center" to={"/comercial"}>PROYECTOS<br></br>COMERCIALES</NavLink>
                        </li>
                        {/*<li>
                            <NavLink to={"/services"}>SERVICIOS</NavLink>
                            <ul className="sub-menu">
                                <li><NavLink to={"/project-carousel"}>Estimación y Propuestas (Residencial)</NavLink></li>
                                <li><NavLink to={"/project-classic1"}>Diseño Arquitectónico Estructural (Comercial)</NavLink></li>
                                <li><NavLink to={"/project-masonary"}>Modelación en 3D</NavLink></li>
                                <li><NavLink to={"/project-grid"}>Permisos de Construcción</NavLink></li>
                                <li><NavLink to={"/project-grid"}>Construcción de Obras Civiles</NavLink></li>
                            </ul>
                        </li>
                        <li>
                            <NavLink to={""}>PROYECTOS</NavLink>
                            <ul className="sub-menu">
                                <li><NavLink to={"/project-carousel"}>Propuestas</NavLink></li>
                                <li><NavLink to={"/project-masonary"}>En Fase de Diseño</NavLink></li>
                                <li><NavLink to={"/project-grid"}>En Progreso</NavLink></li>
                                <li><NavLink to={"/project-classic1"}>Proyectos Finalizados</NavLink></li>
                            </ul>
                        </li>*/}
                    </ul>
                </div>
            </>
        );
    };
};

export default Navigation;